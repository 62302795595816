import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import styled from "styled-components";
import GradientButton from "../../Button/gradient";
import { tabletMediaQuery, responsiveSizes } from "../../../lib/constants";
import ContentLoading from "components/ContentLoading";
import OnlyMobile from "components/OnlyMobile";
import MobileUpwards from "components/MobileUpwards";

const Wrapper = styled.div`
  background: #f6f6f6;
  color: #000000;

  p {
    margin: 0 0 1.5rem 0;
    overflow-y: scroll;
  }
`;

const Content = styled.div`
  margin: auto;
  max-width: ${responsiveSizes.wide};
  display: flex;
  flex-direction: column;

  @media (${tabletMediaQuery}) {
    flex-direction: row;
    max-height: 500px;

    & > * {
      width: 100%;
    }
  }
`;

const StyledVideo = styled.video`
  object-fit: cover;
  object-position: center;
  height: auto;
  width: 100%;

  @media (${tabletMediaQuery}) {
    margin-left: 1.5rem;
    max-height: 100%;
    width: 40%;
  }
`;

const VideoFallbackImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;

  @media (min-width: ${responsiveSizes.mobile}) {
    height: 350px;
  }

  @media (${tabletMediaQuery}) {
    margin-left: 1.5rem;
    height: auto;
    width: 40%;
  }
`;
const StyledGradientButton = styled(GradientButton)`
  &:disabled {
    color: gray;
    background: white;
  }
`;

const About = styled.p`
  line-height: 1.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  @media (${tabletMediaQuery}) {
    height: 130px;
  }
`;

const Title = styled.h2`
  font-size: 1rem;
  margin-bottom: 0.5rem !important;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  padding: 2rem;

  @media (${tabletMediaQuery}) {
    padding: 2rem 2rem 2rem 6rem;
  }
`;

function Subheader({
  about,
  agentFirstName,
  agentLicenseNumber,
  headline,
  loading,
  onAskInformationClick,
  videoImageFallback,
  videoImagePreview,
  videoUrl,
}) {
  return (
    <Wrapper>
      <Content>
        {videoUrl ? (
          <React.Fragment>
            <Head>
              <link rel="preload" href={videoImagePreview} as="image" />
            </Head>
            <StyledVideo controls poster={videoImagePreview} preload="metadata">
              <source src={videoUrl} />
            </StyledVideo>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Head>
              <link rel="preload" href={videoImageFallback} as="image" />
            </Head>
            {loading ? (
              <>
                <OnlyMobile>
                  <ContentLoading height="440" width="100%" />
                </OnlyMobile>
                <MobileUpwards>
                  <ContentLoading height="440" width="300" />
                </MobileUpwards>
              </>
            ) : (
              <VideoFallbackImage src={videoImageFallback} />
            )}
          </React.Fragment>
        )}

        <ContentContainer>
          <h2>
            {loading ? <ContentLoading height="32" width="300" /> : headline}
          </h2>
          {about && <About>{about}</About>}
          {loading && <ContentLoading height="100" width="300" />}
          {(agentLicenseNumber || loading) && <Title>License</Title>}
          {loading ? (
            <ContentLoading height="32" width="100" />
          ) : (
            <p>{agentLicenseNumber}</p>
          )}
          <div style={{ fontWeight: "bold", marginBottom: "0.8rem" }}>
            Looking to buy or sell a home?{" "}
          </div>
          <StyledGradientButton
            bold
            fullWidth={false}
            onClick={onAskInformationClick}
            disabled={loading}
          >
            Ask {agentFirstName} a Question
          </StyledGradientButton>
        </ContentContainer>
      </Content>
    </Wrapper>
  );
}

Subheader.propTypes = {
  about: PropTypes.node.isRequired,
  agentFirstName: PropTypes.string.isRequired,
  agentLicenseNumber: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onAskInformationClick: PropTypes.func.isRequired,
  videoImageFallback: PropTypes.string,
  videoImagePreview: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default Subheader;
