import { tabletMediaQuery } from "../lib/constants";
import styled from "styled-components";

const OnlyMobile = styled.span`
  @media (${tabletMediaQuery}) {
    display: none;
  }
`;

export default OnlyMobile;
