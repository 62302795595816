import React from "react";
import Profile from "../components/Profile";
import { useRouter } from "next/router";
import { crmUserByUsernameQuery } from "components/queries";
import client from "lib/apolloClient";
import ErrorPage from "components/ErrorPage";

import "@propertysimple/styles/styles.css";

export async function getServerSideProps(context) {
  const { username } = context.query;
  const variables = { user_name: username };
  const { data } = await client.query({
    query: crmUserByUsernameQuery,
    variables,
    fetchPolicy: "network-only",
    errorPolicy: "all",
    onError: (error) => {
      throw new Error(
        `Error while retrieving user by username: ${JSON.stringify(error)}`,
      );
    },
  });

  if (context.query["raise-in-pages-router-profile-page-server-side"]) {
    throw new Error("Test error in pages router profile page server side");
  }

  return {
    props: {
      crmUser: data.crmUser,
    },
  };
}

function ProfilePage({ crmUser }) {
  const router = useRouter();
  const { username, videoLanding } = router.query;

  if (crmUser === null) {
    return <ErrorPage />;
  }

  return (
    <Profile
      crmUser={crmUser}
      username={username}
      videoLanding={videoLanding}
    />
  );
}

export default ProfilePage;
