import PropTypes from "prop-types";
import styled from "styled-components";

const SplitContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: ${(props) =>
    props.reverseOrderBelowBreakpoint ? "column-reverse" : "column"};
  box-sizing: border-box;
  ${(props) => props.padding && `padding: ${props.padding};`}
  width: 100%;

  & > * {
    width: 100%;
  }

  @media (min-width: ${(props) => props.breakpoint}) {
    ${(props) =>
      props.sticky &&
      `
      & > * {
        position: sticky;
        top: ${props.offsetTop || 0};
      }
    `}

    flex-direction: row;
    & > *:nth-child(1) {
      width: ${(props) => props.leftSideWidthPercentage}%;
    }
    & > *:nth-child(2) {
      ${(props) => props.gap && `margin-left: ${props.gap};`}
      width: ${(props) => 100 - props.leftSideWidthPercentage}%;
    }
  }
`;

SplitContent.defaultProps = {
  breakpoint: "768px",
  leftSideWidthPercentage: 30,
  sticky: true,
};

SplitContent.propTypes = {
  gap: PropTypes.string,
  leftSideWidthPercentage: PropTypes.number.isRequired,
};

export default SplitContent;
