import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  grayColor,
  lightGrayRGBValues,
  lightGrayColor,
} from "../../lib/colors";

const noSelectableByUser = `
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Label = styled.label`
  ${noSelectableByUser}
  display: inline;
`;

const CheckboxInput = styled.input`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  & ~ span {
    background: white;
    border-radius: 20%;
    border: 2px solid ${grayColor};
    cursor: pointer;
    display: inline-block;
    height: 1rem;
    position: relative;
    vertical-align: middle;
    width: 1rem;
  }

  /* Styles for the focus state of the custom checkbox */
  &:focus ~ span {
    border-color: ${lightGrayColor};
    box-shadow: 0 0 0 1px rgba(${lightGrayRGBValues}, 0.25);
  }

  /* Styles for the checked state of the custom checkbox */
  &:checked ~ span {
    background: ${grayColor}
      url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=)
      center no-repeat;
    background-size: 75%;
    border-color: ${grayColor};
  }

  &:hover ~ span {
    border-color: ${lightGrayColor};
  }
`;

function Checkbox(props) {
  return (
    <Label className={props.className}>
      <CheckboxInput type="checkbox" {...props} />
      <span />
    </Label>
  );
}

Checkbox.defaultProps = {};

Checkbox.propTypes = {
  className: PropTypes.string,
};

export default Checkbox;
