import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "./Header";
import Subheader from "./Subheader";
import { processImage, processBusinessCardPhoto } from "lib/util";
import {
  responsiveSizes,
  blueColorWithOpacity,
  orangeColorWithOpacity,
  pinkColorWithOpacity,
  purpleColorWithOpacity,
  yellowColorWithOpacity,
} from "lib/constants";
import ContactForm from "components/ContactForm";
import { PortalFooterWhite } from "@propertysimple/components";
import AgentContactModal from "components/AgentContactModal";
import Head from "next/head";
import {
  blueColor,
  orangeColor,
  pinkColor,
  purpleColor,
  yellowColor,
} from "lib/colors";
import { userProfileUrl } from "lib/urls";
import CreateInteraction from "components/CreateInteraction";
import SplitContent from "components/Article/SplitContent";
import Report from "components/Report";
import SEO from "components/SEO";
import dynamic from "next/dynamic";
import { encode } from "html-entities";

const Navbar = dynamic(() => import("components/Navbar/New"));
const RelatedDataQuery = dynamic(() => import("components/RelatedData/Query"));

const agentImageSizeInPixels = 130;

const Wrapper = styled.article`
  h2,
  h3 {
    margin-top: 0;
  }

  h2 {
    margin-bottom: 1rem;
  }
  h3 {
    margin-bottom: 0.5rem;
  }
`;

const Content = styled.div`
  margin: auto;
  max-width: ${responsiveSizes.wide};
  box-sizing: border-box;
  padding: 2rem;
`;

const coverBackgroundColorToHexColor = (coverBackgroundColor) => {
  switch (coverBackgroundColor) {
    case "yellow":
      return `linear-gradient(to right, ${orangeColor}, ${yellowColor})`;
    case "blue":
      return `linear-gradient(to right, ${purpleColor}, ${blueColor})`;
    case "pink":
      return `linear-gradient(to right, ${purpleColor}, ${pinkColor})`;
    default:
      return `linear-gradient(to right, ${purpleColor}, ${pinkColor})`;
  }
};

const coverBackgroundColorToLinearGradient = (coverBackgroundColor) => {
  const BACKGROUND_OPACITY = 0.7;

  switch (coverBackgroundColor) {
    case "yellow":
      return `linear-gradient(to right, ${orangeColorWithOpacity(BACKGROUND_OPACITY)}, ${yellowColorWithOpacity(
        BACKGROUND_OPACITY,
      )})`;
    case "blue":
      return `linear-gradient(to right, ${purpleColorWithOpacity(BACKGROUND_OPACITY)}, ${blueColorWithOpacity(
        BACKGROUND_OPACITY,
      )})`;
    case "pink":
      return `linear-gradient(to right, ${purpleColorWithOpacity(BACKGROUND_OPACITY)}, ${pinkColorWithOpacity(
        BACKGROUND_OPACITY,
      )})`;
    default:
      return null;
  }
};

const agentPageTitle = (agent) => {
  if (agent.city) {
    return `${agent.fullName} - ${agent.city} Real Estate Agent`;
  } else {
    return `${agent.fullName} - Real Estate Agent`;
  }
};

function Profile({ crmUser, username, videoLanding }) {
  const [agentHasRelatedData, setAgentHasRelatedData] = useState(true);
  const [displayContactModal, setDisplayContactModal] = useState(false);

  const {
    accountType,
    brokerageAddress,
    brokerageName,
    brokerage,
    email,
    firstName,
    fullName,
    user,
    zips,
  } = crmUser || {};
  const { coverBackgroundImage, headline, phone } = user || {};
  const isAreaExpert = zips?.[0]?.zipcode;
  const coverBackgroundColor = user.coverBackgroundColor || "pink";
  const linearGradient =
    coverBackgroundColorToLinearGradient(coverBackgroundColor);
  const background = coverBackgroundImage;
  const backgroundMobile = `/images/${coverBackgroundColor}-400.jpg`;
  const temporaryBackgroundColor =
    coverBackgroundColorToHexColor(coverBackgroundColor);
  let avatarUrl = crmUser?.business_card_photo || brokerage?.logo_url;

  // Ogtags need to be at least 200 x 200 pixels
  const ogTagsAvatarUrl = avatarUrl;
  avatarUrl =
    avatarUrl &&
    processBusinessCardPhoto(avatarUrl, {
      h: agentImageSizeInPixels * 2,
      w: agentImageSizeInPixels * 2,
    });

  const videoBusinessCardUrl = crmUser?.business_card_video?.video_link;
  const videoImagePreview =
    user && processImage(user.location_image, { width: 960, height: 960 });
  const canonicalUrl = userProfileUrl(crmUser.user, {
    videoLanding: true,
    fullUrl: true,
  });

  const facebookUrl = crmUser?.fb_business_page?.business_page_url;
  const instagramUsername = user?.instagramUsername;
  const instagramUrl =
    instagramUsername && `https://instagram.com/${instagramUsername}`;
  const linkedinUrl = user?.linkedinProfileUrl;
  const twitterUrl = user?.twitterProfileUrl;

  const about = crmUser?.about?.replace(/(?:\r\n|\r|\n)/g, "<br />");
  const contactForm = (
    <ContactForm
      breakpoint={9999}
      titles={[
        "Home valuation",
        "Increasing your home value",
        "Private showings",
        "Newest properties and price changes",
      ]}
      crmUser={crmUser}
      onCallToActionClick={() => setDisplayContactModal(true)}
      title={
        firstName &&
        `Connect with ${firstName} for valuable real estate advice!`
      }
    />
  );

  return (
    <Wrapper>
      <Navbar />
      <Report
        level="debug"
        message="Bot request for profile"
        variables={{
          crmUserID: crmUser && crmUser.id,
          userID: crmUser && crmUser.user && crmUser.user.id,
        }}
      />
      {videoLanding !== "true" && (
        <SEO
          description={headline}
          image={ogTagsAvatarUrl}
          ogType="profile"
          title={agentPageTitle(crmUser)}
          url={canonicalUrl}
          noIndex={crmUser.is_title_company}
        />
      )}
      <Head>
        <link rel="preconnect" href="https://img.propertysimple.com" />
        <link rel="preload" href={avatarUrl} as="image" />
        <title>{agentPageTitle(crmUser)}</title>
        <link rel="canonical" href={canonicalUrl} />

        <meta name="description" content={headline} />

        {videoLanding === "true" ? (
          <>
            <meta property="og:type" content="video.other" />
            <meta
              property="og:title"
              content={`Watch ${fullName}'s Video Business Card on PropertySimple`}
            />
            <meta property="og:video" content={videoBusinessCardUrl} />
            <meta property="og:url" content={canonicalUrl} />
            <meta
              property="og:video:secure_url"
              content={videoBusinessCardUrl}
            />
            <meta property="og:video:type" content="video/mp4" />
            <meta property="og:video:width" content="398" />
            <meta property="og:video:height" content="264" />
            <meta property="og:site_name" content="PropertySimple" />
            <meta property="og:image" content={user?.location_image} />
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `{
                  "@context": "http://schema.org",
                  "@type": "VideoObject",
                  "description": "${encode(fullName)}'s Video Business Card",
                  "name": "${encode(fullName)} - Real Estate Agent${crmUser.city ? ` – ${crmUser.city}` : ""}",
                  "thumbnail": "${avatarUrl}",
                  "thumbnailUrl": "${avatarUrl}",
                  "uploadDate": "${crmUser.createdAt ? new Date(crmUser.createdAt * 1000).toISOString() : ""}",
                  "contentUrl": "${videoBusinessCardUrl}",
                  "url": "${canonicalUrl}"
                }`,
              }}
            />
          </>
        ) : (
          <>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `{
                  "@context": "http://schema.org",
                  "@type": "Person",
                  "name": "${encode(fullName)}",
                  "address": "${brokerageAddress}",
                  "email": "${email}",
                  "image": "${avatarUrl}",
                  "telephone": "${phone}",
                  "url": "${canonicalUrl}",
                  "workLocation": "${brokerageAddress}"
                }`,
              }}
            />
          </>
        )}
      </Head>
      <Header
        address={brokerageAddress}
        avatarUrl={avatarUrl}
        background={background}
        hasFreeAccount={accountType === "free"}
        linearGradient={linearGradient}
        backgroundColor={coverBackgroundColor}
        backgroundMobile={backgroundMobile}
        temporaryBackgroundColor={temporaryBackgroundColor}
        brokerage={brokerageName}
        firstName={firstName}
        fullName={fullName}
        facebookUrl={facebookUrl}
        instagramUrl={instagramUrl}
        isAreaExpert={isAreaExpert}
        linkedinUrl={linkedinUrl}
        twitterUrl={twitterUrl}
        onAskInformationClick={() => setDisplayContactModal(true)}
      />
      <Subheader
        headline={headline}
        about={<span dangerouslySetInnerHTML={{ __html: about }} />}
        agentLicenseNumber={crmUser.agentLicenseNumber}
        agentFirstName={firstName}
        onAskInformationClick={() => setDisplayContactModal(true)}
        videoImageFallback="https://s3-us-west-2.amazonaws.com/static.propertysimple.com/images/listing-ssr/no-video-business-card-placeholder.jpg"
        videoUrl={videoBusinessCardUrl}
        videoImagePreview={videoImagePreview}
      />
      <Content>
        {agentHasRelatedData ? (
          <SplitContent gap="2rem" offsetTop="5rem">
            {contactForm}
            <RelatedDataQuery
              username={username}
              onCompleted={({ hasRelatedData }) =>
                setAgentHasRelatedData(hasRelatedData)
              }
            />
          </SplitContent>
        ) : (
          <div style={{ margin: "auto", maxWidth: "400px" }}>{contactForm}</div>
        )}
      </Content>
      {displayContactModal && (
        <AgentContactModal
          crmUser={crmUser}
          crmUserID={crmUser?.id}
          initialMessageBody="I'm interested in your real estate services. Can you give me a call?"
          onClose={() => setDisplayContactModal(false)}
        />
      )}
      <CreateInteraction
        input={{
          crm_user_id: crmUser?.id,
          interaction_name: "agent_profile_visit",
        }}
      />
      <PortalFooterWhite environment={process.env.APP_ENVIRONMENT} />
    </Wrapper>
  );
}

Profile.propTypes = {
  username: PropTypes.string.isRequired,
  videoLanding: PropTypes.string,
};

export default Profile;
