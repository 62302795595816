import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RoundImage from "components/RoundImage";
import Button from "../../Listing/Button";
import { MapMarker } from "../../../lib/svg/fa";
import {
  colorPink,
  desktopMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from "../../../lib/constants";
import OnlyMobile from "../../OnlyMobile";
import TabletUpwards from "../../TabletUpwards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import ContentLoader from "react-content-loader";

const HORIZONTAL_PADDING = "2rem";

const BackgroundFallback = styled.div`
  background-image: url("/images/background-320-2x.jpg");
  background-position: center;
  background-size: cover;

  @media (min-width: 480px) {
    background-image: url("/images/background-480-2x.jpg");
  }
  @media (min-width: 768px) {
    background-image: url("/images/background-768-2x.jpg");
  }
  @media (min-width: 1200px) {
    background-image: url("/images/background-1200-2x.jpg");
  }
`;
const Wrapper = styled.header`
  ${(props) =>
    `background-image: ${props.linearGradient}, url(${props.background});`}
  ${(props) => props.loading && `background-color: #E0E0E0;`}
  background-size: cover;
  background-position: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7rem ${HORIZONTAL_PADDING} 3.5rem;
  margin: auto;

  h2,
  h3 {
    margin: 0;
  }
  h2 {
    margin-bottom: 1rem;
  }

  @media (${tabletMediaQuery}) {
    align-items: center;
    padding: 7rem ${HORIZONTAL_PADDING} 4rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.125em;
  margin-right: 1rem;
  display: block;
  margin-bottom: 0.5rem;
  @media (${mobileMediaQuery}) {
    margin-bottom: none;
  }
`;

const SocialNetworks = styled.div`
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
    &:active {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
`;

const Brokerage = styled.span`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Address = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  margin-top: 0.8rem;
  padding: 0.8rem 1.5rem;

  &:disabled {
    color: gray;
  }
`;

const AreaExpertBadge = styled.span`
  background: #ffffff;
  color: ${colorPink};
  border-radius: 1rem;
  font-size: 1rem;
  padding: 0.2rem 0.8rem 0.1rem 0.8rem;
  font-weight: bold;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;

  @media (${mobileMediaQuery}) {
    margin-bottom: none;
  }

  @media (${desktopMediaQuery}) {
    padding: 0.3rem 1rem 0.2rem 1rem;
  }
`;

const AREA_EXPERT_BADGE_SIZE = "30px";
const AREA_EXPERT_BADGE_BORDER_SIZE = "3px";

const AreaExpertStar = styled.div`
  background-image: linear-gradient(255deg, #ff3867, #ac00bd);
  border: ${AREA_EXPERT_BADGE_BORDER_SIZE} solid #ffffff;
  border-radius: ${AREA_EXPERT_BADGE_SIZE};
  font-size: 1.2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: ${AREA_EXPERT_BADGE_SIZE};
  width: ${AREA_EXPERT_BADGE_SIZE};

  position: absolute;
  right: -1px;
  bottom: 4px;
  @media (${mobileMediaQuery}) {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 0.75rem;
    right: 14px;
    bottom: 3px;
  }
`;

const StyledStarFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 1rem;
  height: 1rem;
`;

function Header({
  address,
  avatarUrl,
  background,
  backgroundColor,
  backgroundMobile,
  brokerage,
  facebookUrl,
  firstName,
  fullName,
  hasFreeAccount,
  instagramUrl,
  isAreaExpert,
  linearGradient,
  linkedinUrl,
  loading,
  onAskInformationClick,
  twitterUrl,
}) {
  const avatarImageAltText = `${fullName}'s avatar`;
  const areaExpertStar = isAreaExpert && (
    <AreaExpertStar>
      <StyledStarFontAwesomeIcon icon={faStar} />
    </AreaExpertStar>
  );

  return (
    <BackgroundFallback>
      <Wrapper
        background={background}
        backgroundColor={backgroundColor}
        backgroundMobile={backgroundMobile}
        linearGradient={linearGradient}
        loading={loading}
      >
        <TabletUpwards>
          <div style={{ position: "relative", marginRight: "3rem" }}>
            <RoundImage
              loading={loading}
              alt={avatarImageAltText}
              border
              size={120}
              src={avatarUrl}
            />
            {areaExpertStar}
          </div>
        </TabletUpwards>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OnlyMobile style={{ marginBottom: "1rem" }}>
              <div style={{ position: "relative" }}>
                <RoundImage
                  loading={loading}
                  alt={avatarImageAltText}
                  border
                  size={90}
                  src={avatarUrl}
                  style={{ marginRight: "1rem" }}
                />
                {areaExpertStar}
              </div>
            </OnlyMobile>
            <TitleWrapper>
              {loading ? (
                <ContentLoader
                  height={32}
                  width={100}
                  style={{ marginBottom: "1rem", width: "100px" }}
                />
              ) : (
                <Title>{fullName}</Title>
              )}
              {isAreaExpert && <AreaExpertBadge>Area Expert</AreaExpertBadge>}
              {!hasFreeAccount && (
                <SocialNetworks>
                  {facebookUrl && (
                    <a
                      href={facebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledFontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                  )}
                  {twitterUrl && (
                    <a
                      href={twitterUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledFontAwesomeIcon icon={faTwitterSquare} />
                    </a>
                  )}
                  {linkedinUrl && (
                    <a
                      href={linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledFontAwesomeIcon icon={faLinkedin} />
                    </a>
                  )}
                  {instagramUrl && (
                    <a
                      href={instagramUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledFontAwesomeIcon icon={faInstagram} />
                    </a>
                  )}
                </SocialNetworks>
              )}
            </TitleWrapper>
          </div>
          {brokerage && <Brokerage>{brokerage}</Brokerage>}

          {address && (
            <Address>
              <MapMarker height="1.2rem" style={{ marginRight: "0.5rem" }} />
              {address}
            </Address>
          )}

          <div>Looking to buy or sell a home?</div>

          <StyledButton
            border={false}
            disabled={loading}
            onClick={onAskInformationClick}
          >
            Ask {firstName} a Question
          </StyledButton>
        </div>
      </Wrapper>
    </BackgroundFallback>
  );
}

Header.defaultProps = {
  isAreaExpert: false,
};

Header.propTypes = {
  address: PropTypes.string,
  avatarUrl: PropTypes.string.isRequired,
  brokerage: PropTypes.string,
  background: PropTypes.string,
  isAreaExpert: PropTypes.bool,
  fullName: PropTypes.string.isRequired,
  onAskInformationClick: PropTypes.func.isRequired,
  temporaryBackgroundColor: PropTypes.string,
};

export default Header;
