import React from "react";
import styled from "styled-components";

const ButtonComponent = styled.button`
  background: #ffffff;
  border: 1px solid #ff3867;
  border-radius: 6px;
  color: #ff3867;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  outline: none;

  &:hover {
    color: #cd2950;
  }
  ${(props) => !props.border && "border: none;"}
  ${(props) => props.fullWidth && "width: 100%;"}
`;

export default function Button({
  border = true,
  fullWidth = false,
  children,
  ...rest
}) {
  return <ButtonComponent {...{ border, fullWidth, children, ...rest }} />;
}
