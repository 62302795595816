import { mobileMediaQuery } from "../lib/constants";
import styled from "styled-components";

const TabletUpwards = styled.span`
  @media (${mobileMediaQuery}) {
    display: none;
  }
`;

export default TabletUpwards;
