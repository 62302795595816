import React from "react";
import AgentInformation, {
  AgentInformationCrmUserFragment,
  AgentInformationCrmUserMock,
} from "../AgentInformation";
import GradientButton from "../Button/gradient";
import HorizontalLine from "../HorizontalLine";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import styled from "styled-components";
import {
  desktopMediaQuery,
  responsiveSizesInPixels,
} from "../../lib/constants";
import { filter } from "graphql-anywhere";
import Checkboxes from "./Checkboxes";

const Wrapper = styled.form`
  box-shadow: 0 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.4rem;
  box-sizing: border-box;
  padding: 2rem;
`;

const StyledGradientButton = styled(GradientButton)`
  font-weight: bold;
  margin-top: 1rem;

  @media (${desktopMediaQuery}) {
    max-width: 320px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.fullWidth ? "row" : "column")};
  ${(props) =>
    props.fullWidth &&
    `
    & > * {
      box-sizing: border-box;
      width: 50%;
    }
  `}

  @media (min-width: ${(props) => props.breakpoint}px) {
    flex-direction: row;
    align-items: center;

    & > * {
      width: 50%;
    }
  }
`;

const AgentInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (min-width: ${(props) => props.breakpoint}px) {
    justify-content: center;
  }
`;

const StyledHorizontalLine = styled(HorizontalLine)`
  @media (min-width: ${(props) => props.breakpoint}px) {
    display: none;
  }
`;

export const ContactFormCrmUserMock = {
  ...AgentInformationCrmUserMock,
};

export const ContactFormCrmUserFragment = gql`
  fragment ContactFormCrmUserFragment on CrmUser {
    ...AgentInformationCrmUserFragment
  }
  ${AgentInformationCrmUserFragment}
`;

 
function ContactForm({
  breakpoint,
  titles,
  className,
  crmUser,
  displayLinkToProfile,
  fullWidth,
  loading,
  onCallToActionClick,
  title,
}) {
  const onClick = (e) => {
    e.preventDefault();
    onCallToActionClick();
  };

  return (
    <Wrapper className={className}>
      <Content breakpoint={breakpoint} fullWidth={fullWidth}>
        <AgentInformationWrapper breakpoint={breakpoint}>
          <AgentInformation
            {...{
              loading,
              title,
              displayLinkToProfile,
              crmUser: filter(AgentInformationCrmUserFragment, crmUser || null),
            }}
          />
        </AgentInformationWrapper>
        {!fullWidth && <StyledHorizontalLine breakpoint={breakpoint} />}
        <div>
          <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            Message for the agent:
          </p>
          <Checkboxes titles={titles} rowGap={0} />
          <StyledGradientButton fullWidth onClick={onClick}>
            Ask a Question
          </StyledGradientButton>
        </div>
      </Content>
    </Wrapper>
  );
}

ContactForm.defaultProps = {
  breakpoint: responsiveSizesInPixels.desktop,
  onCallToActionClick: () =>
    console.error("onCallToActionClick is not defined"),
};

ContactForm.propTypes = {
  breakpoint: PropTypes.number,
  className: PropTypes.string,
  crmUser: PropTypes.any,
  displayLinkToProfile: PropTypes.bool,
  name: PropTypes.string,
  onCallToActionClick: PropTypes.func,
  title: PropTypes.string,
};

export default ContactForm;
