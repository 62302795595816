import { mobileMediaQuery } from "../lib/constants";
import styled from "styled-components";

const MobileUpwards = styled.span`
  @media (${mobileMediaQuery}) {
    display: none;
  }
`;

export default MobileUpwards;
