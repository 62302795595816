import React from "react";

export const FaCalendarAlt = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="calendar-alt"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
    ></path>
  </svg>
);

export const FaCalendarStar = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="calendar-star"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill="currentColor"
      d="M149.8 369l-7.9 46.3c-1.8 10.6 2.5 21.1 11.2 27.4 4.9 3.5 10.7 5.4 16.4 5.4 4.4 0 8.9-1.1 13-3.3L224 423l41.5 21.8c9.5 5 20.8 4.2 29.5-2.1 8.7-6.3 13-16.8 11.2-27.4l-7.9-46.3 33.5-32.7c7.7-7.5 10.4-18.5 7.1-28.7s-12-17.5-22.6-19l-46.4-6.8-20.7-42.1C244.3 230 234.7 224 224 224s-20.3 6-25 15.6l-20.7 42.1-46.4 6.8c-10.6 1.5-19.3 8.8-22.6 19s-.6 21.2 7.1 28.7l33.4 32.8zm49.6-58.1L224 261l24.6 49.9 55 8-39.8 38.9 9.4 54.8-49.2-25.8-49.2 25.9 9.4-54.8-39.8-38.9 55-8.1zM400 64h-48V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56H128V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V192h384v272zm0-304H32v-48c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v48z"
    ></path>
  </svg>
);

export const FaHomeIcon = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="home-lg"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M573.48 219.91L512 170.42V72a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v72.6L310.6 8a35.85 35.85 0 0 0-45.19 0L2.53 219.91a6.71 6.71 0 0 0-1 9.5l14.2 17.5a6.82 6.82 0 0 0 9.6 1L64 216.72V496a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V216.82l38.8 31.29a6.83 6.83 0 0 0 9.6-1l14.19-17.5a7.14 7.14 0 0 0-1.11-9.7zM336 480h-96V320h96zm144 0H368V304a16 16 0 0 0-16-16H224a16 16 0 0 0-16 16v176H96V190.92l187.71-151.4a6.63 6.63 0 0 1 8.4 0L480 191z"
    ></path>
  </svg>
);

export const FaList = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="sign"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M504 64H96V8c0-4.4-3.6-8-8-8H72c-4.4 0-8 3.6-8 8v56H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h56v408c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h96v48h-16c-17.7 0-32 14.3-32 32v176c0 17.7 14.3 32 32 32h288c17.7 0 32-14.3 32-32V176c0-17.7-14.3-32-32-32h-16V96h56c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm-40 288H176V176h288v176zm-48-208H224V96h192v48z"
    />
  </svg>
);

export const FaTree = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="sign"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M504 64H96V8c0-4.4-3.6-8-8-8H72c-4.4 0-8 3.6-8 8v56H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h56v408c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h96v48h-16c-17.7 0-32 14.3-32 32v176c0 17.7 14.3 32 32 32h288c17.7 0 32-14.3 32-32V176c0-17.7-14.3-32-32-32h-16V96h56c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm-40 288H176V176h288v176zm-48-208H224V96h192v48z"
    />
  </svg>
);

export const FaUsdCircle = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="usd-circle"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm40.3-221.3l-72-20.2c-12.1-3.4-20.6-14.4-20.6-26.7 0-15.3 12.8-27.8 28.5-27.8h45c11.2 0 21.9 3.6 30.6 10.1 3.2 2.4 7.6 2 10.4-.8l11.3-11.5c3.4-3.4 3-9-.8-12-14.6-11.6-32.6-17.9-51.6-17.9H264v-40c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v40h-7.8c-33.3 0-60.5 26.8-60.5 59.8 0 26.6 18.1 50.2 43.9 57.5l72 20.2c12.1 3.4 20.6 14.4 20.6 26.7 0 15.3-12.8 27.8-28.5 27.8h-45c-11.2 0-21.9-3.6-30.6-10.1-3.2-2.4-7.6-2-10.4.8l-11.3 11.5c-3.4 3.4-3 9 .8 12 14.6 11.6 32.6 17.9 51.6 17.9h5.2v40c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-40h7.8c33.3 0 60.5-26.8 60.5-59.8-.1-26.6-18.1-50.2-44-57.5z"
    ></path>
  </svg>
);

export const MapMarker = (props) => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="map-marker-alt"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
  >
    <path
      fill="currentColor"
      d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"
    ></path>
  </svg>
);

export const PhoneLight = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="phone"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="currentColor"
      d="M487.8 24.1L387 .8c-14.7-3.4-29.8 4.2-35.8 18.1l-46.5 108.5c-5.5 12.7-1.8 27.7 8.9 36.5l53.9 44.1c-34 69.2-90.3 125.6-159.6 159.6l-44.1-53.9c-8.8-10.7-23.8-14.4-36.5-8.9L18.9 351.3C5 357.3-2.6 372.3.8 387L24 487.7C27.3 502 39.9 512 54.5 512 306.7 512 512 307.8 512 54.5c0-14.6-10-27.2-24.2-30.4zM55.1 480l-23-99.6 107.4-46 59.5 72.8c103.6-48.6 159.7-104.9 208.1-208.1l-72.8-59.5 46-107.4 99.6 23C479.7 289.7 289.6 479.7 55.1 480z"
    ></path>
  </svg>
);
