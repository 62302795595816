import React from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

const StyledContentLoader = styled(ContentLoader)`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}${(props) => !props.widthIsPercentage && "px"};
`;

const isPercentage = (value) => {
  return typeof value === "string" && value.endsWith("%");
};

function ContentLoading({ height = 10, width = 300 }) {
  const percentage = isPercentage(width);

  return (
    <StyledContentLoader
      speed={2}
      widthIsPercentage={percentage}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#fffefe"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
    </StyledContentLoader>
  );
}

ContentLoading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ContentLoading;
