import PropTypes from "prop-types";
import styled from "styled-components";

const Grid = styled.div`
  ${(props) =>
    props.maxHeight &&
    `
    & > * {
      max-height: ${props.maxHeight};
    }
  `}
  ${(props) => props.justifyItems && `justify-items: ${props.justifyItems};`}
  ${(props) => props.centerVertically && "align-items: center;"}
  display: grid;
  column-gap: ${(props) => props.columnGap};
  grid-template-columns: repeat(1, minmax(0px, 1fr));
  row-gap: ${(props) => props.rowGap};

  ${(props) =>
    Object.keys(props.breakpoints).map((breakpoint) => {
      const rows = props.breakpoints[breakpoint];

      return `
      @media(min-width: ${breakpoint}px) {
        grid-template-columns: repeat(${rows}, minmax(0px, 1fr));
      }
    `;
    })}
`;

Grid.defaultProps = {
  breakpoints: {},
  columnGap: "1em",
  rowGap: "1em",
};

Grid.propTypes = {
  breakpoints: PropTypes.object,
  columnGap: PropTypes.string,
  rowGap: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default Grid;
