import React from "react";
import Checkbox from "../Checkbox";
import styled from "styled-components";
import Grid from "components/GridNew";

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

function Checkboxes({ titles = [], rows = 1, columnGap, rowGap }) {
  return (
    <Grid breakpoints={{ 0: rows }} columnGap={columnGap} rowGap={rowGap}>
      {titles.map((title) => (
        <StyledLabel key={title}>
          <Checkbox defaultChecked />
          &nbsp;&nbsp;{title}
        </StyledLabel>
      ))}
    </Grid>
  );
}

export default Checkboxes;
